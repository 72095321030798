<template>
<div  class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>   
          <div class="search-form">
            <el-form ref="form" :inline="true"  :model="search_key" prop="">
                <el-form-item label="角色名称">
                    <el-input v-model="search_key.name"></el-input>
                </el-form-item>
				<el-form-item label="企业" v-if="roleId == 1">
					   <el-select v-model="search_key.coid" placeholder="请选择" style="width:100%">
							<el-option v-for="(item,index) in coList" :key="index" :label="item.title" :value="item.id"></el-option>
					   </el-select>
				</el-form-item>
                <el-button type="primary" @click="getData()">查询</el-button>
            </el-form>
         </div>
          <div style="margin-bottom:10px;">
             <el-button size="small" type="" @click="handleAdd()">添加</el-button>
         </div>
		<el-tabs  @tab-click="handleClick">
			<el-tab-pane label="系统角色" >
			</el-tab-pane>
			<el-tab-pane label="审批角色" >
			</el-tab-pane>
		</el-tabs>
         <el-table
             :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)" 
             border
			 @cell-click="changeCell"
             style="width: 100%">
             <!-- <el-table-column
                type="selection">
             </el-table-column> -->
             <el-table-column
                fixed
                prop="id"
                label="ID">
             </el-table-column>
               <el-table-column
                fixed
                prop="name"
                label="名称">
             </el-table-column>
			 <template v-if="search_key.type == 2">
				 <el-table-column
				    empty-text='-'
				    prop="role_admin_count"
				    label="使用人数">
					<template slot-scope="scope">
						<span style="color: #67C23A;" v-if="scope.row.role_admin_count">{{scope.row.role_admin_count}}</span>
						<span v-else>-</span>
					</template>
				 </el-table-column>
			 </template>
         	<el-table-column
         	 empty-text="-"
         	   prop="remark"
         	   label="角色说明">
         	</el-table-column>
             <el-table-column fixed  prop="status" label='状态'>
                     <template slot-scope="scope">
                         <span v-if="scope.row.status == 1"  style='color:#67C23A;'>启用</span>
                         <span v-else style="color:#909399;">禁用</span>
                     </template> 
             </el-table-column>
            <el-table-column
             fixed="right"
             label="操作">
             <template slot-scope="scope">
				  <el-button v-if="search_key.type == 2" size="small" @click="handleAddStaff(scope.row.id)">添加人员</el-button>
                 <el-button  size="small" @click="handleEdit(scope.row)">编辑</el-button>
                <!-- <el-button  size="small" type="primary" plain  @click="handleClick(scope.row)" >查看</el-button> -->
                <el-button  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
             </template>
             </el-table-column>
          </el-table>
         <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
         <AddVue ref="childDialog"></AddVue>
		 <AddStaffVue ref="addStaffDialog"></AddStaffVue>
		 <AdminVue ref="adminDialog"></AdminVue>
    </div>
</template>

<script>
import AddVue from './Add.vue'
import AddStaffVue from './AddStaff.vue'
import AdminVue from './Admin.vue'
// import approvalIndexVue from './approvalIndex.vue'
export default {
     inject:['reload'],
     components:{
        AddVue,
		AddStaffVue,
		AdminVue
    },
    data() {
        return {
          tableData:[],
          menuList:[],
          search_key:{
              name:'',
			  type:1,
			  coid:''
          },
          dialogAddVisible:false,
          form:{
              name:"",
              status:"1",
              remark:"",
              selectedIds:[],
          },
           multipleSelection:[],
           currentPage:1,
           pagesize:10,
            total:0,
			roleId:localStorage.getItem('role_id'),     //当前登录角色
			coList:[]
        }
    },
    created() {
         this.getData()   
    },
    mounted() {

    },
    methods:{
         getData(){
           this.$api.post('Role/index',{
			   page:this.currentPage,
			   limit:this.pagesize,
			   name:this.search_key.name,
			   type:this.search_key.type,
			   coid:this.search_key.coid
			   
		   },res=>{
               if(res.code == 200){
                   this.tableData =  res.data.data
                   this.total =  res.data.count
				   if(this.roleId == 1){
					   this.$api.post('Company/getCompanyList',null,res=>{
						   if(res.code == 200){
							   this.coList =  res.data
						   }
					   })
				   }
               }
           })   
       },
	   
	   handleClick(val){
		   if(val.index < 1){	//系统角色
			   this.search_key.type =  1
		   }else{	//审批角色
			   this.search_key.type =  2
		   }
		   this.getData()
	   },
       handleEdit(row){
		    this.$refs.childDialog.form.type = this.search_key.type
            this.$refs.childDialog.showDialog(row.id)  
       },
	   
	   handleAdd(){
		   this.$refs.childDialog.form.type = this.search_key.type
	       this.$refs.childDialog.showDialog()
	   },
	   handleAddStaff(id){  
		    this.$refs.addStaffDialog.handleAdd(id)
	   },
	   
	   changeCell(row,column,cell,event){
		  
		   if(column.property == 'role_admin_count'){	//查看使用成员
			   // let val  = row[column.property]
			   this.$refs.adminDialog.roleId =  row['id']
			   this.$refs.adminDialog.getData()  
		   }
	   },
       
       handleDel(row){
            this.$alert("确定删除该角色吗？", "删除提示", {
                // confirmButtonText:'确定',
                showConfirmButton: true,
                showCancelButton: true,
                callback: action => {
                    if (action == "confirm") {
                        this.$api.delete("role/del", { "id": row.id }, res => {
                            if (res.code == 200) {
                                this.$message.success(res.msg);
                                this.reload();
                            }else{
                                  this.$message.error(res.msg)
                            }
                        });
                    }
                    else if (action == "cancel") {
                        this.reload();
                    }
                }
            });
       },

       // search(){
       //     this.$api.post('Role/index',{'name':this.search_key.name},res=>{
       //         if (res.code == 200) {
       //             this.tableData =  res.data.data
       //         }
       //     })
       // },
        getCurrPage(val){   //获取当前页
            this.currentPage =  val
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
        },
    },
}
</script>
<style scoped lang="scss">
    .page-container{
        .search-form{
            height: 60px;
            margin:20px 0px ;
            // margin-top: 20px;
            background: #f2f2f2;
            padding: 20px 0px 0px 15px;
        }
    }   
</style>