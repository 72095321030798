<template>
<div>
    <el-dialog title="添加角色" :visible.sync="dialogAddVisible" width="60%" :close-on-click-modal="false" >
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
         <el-tabs>
              <el-tab-pane label="角色信息" >
				  <template v-if="roleId == 1">
					  <el-form-item label="企业" prop="">
						   <el-select v-model="form.coid" placeholder="请选择" style="width:100%">
								<el-option v-for="(item,index) in coList" :key="index" :label="item.title" :value="item.id"></el-option>
						   </el-select>
					  </el-form-item>
				  </template>
                    <el-form-item label="角色名称" prop="name">
                        <el-input  v-model="form.name"></el-input>
                    </el-form-item>
					<el-form-item label="备注" prop="remark">
					    <el-input  type="textarea" v-model="form.remark"></el-input>
					</el-form-item>
					<el-form-item label="管理员角色" prop="admin_status">
					   <template>
					       <el-radio v-model="form.admin_status" label="0">否</el-radio>
					       <el-radio v-model="form.admin_status" label="1">是</el-radio>
					   </template>
					</el-form-item>
                    <el-form-item label="状态" prop="status">
                        <template>
                            <el-radio v-model="form.status" label="0">禁用</el-radio>
                            <el-radio v-model="form.status" label="1">启用</el-radio>
                        </template>
                    </el-form-item>
              </el-tab-pane>
              <el-tab-pane label="权限编辑" v-if="form.type == 1">
                  <!-- @selection-change="handleSelectionChange"   @select="(selection, row) => handleSelectionChange(selection, row)" -->
                    <el-form-item label="权限" prop="permissions">
                        <el-table
                        ref="multipleTable"
                        :data="menuList"
                        style="width: 100%;margin-bottom: 20px;"
                        row-key="id"
                        border
                        @select-all="handleAllSelection"
                        @select="(selection, row) => handleSelectionChange(selection, row)"
                        @selection-change="handleChange"
                        :tree-props="{children: 'children', hasChildren: 'hasChildren'}" prop=''> 
                            <el-table-column
                            type="selection">
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="菜单">
                            </el-table-column>
                            <el-table-column
                            prop="url"
                            label="路径"
                            >
                            </el-table-column>
                            <el-table-column 
                            prop="sort" 
                            label="排序" 
                            sortable>
                            </el-table-column>
                    </el-table>
                 </el-form-item> 
              </el-tab-pane>
         </el-tabs>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogAddVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm()">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
export default {
    inject:['reload'],
    data() {
        return {
            menuList:[],
            form:{
                id:'',
                name:"",
                status:"1",
                remark:"",
				admin_status:0,
                selectedIds:[],
				type:1,
				coid:''
            },
			roleId:localStorage.getItem('role_id'),     //当前登录角色
            multipleSelection:[],
            dialogAddVisible:false,
			rules: {
				name: [{
					required: true,
					message: '请输入角色名称',
					trigger: 'blur'
				}],
			},
			coList:[]
        }
    },
    created() {
	
    },
    mounted() {

    },
    methods:{
        showDialog(id=0){
			console.log(this.form.type)
            if(id > 0){
                this.handleEdit(id)
            }else{
                this.handleAdd()
            }
        },
        handleEdit(id){
           this.$api.get('role/edit',{'id':id},res=>{
               if(res.code == 200){
                    this.dialogAddVisible =  true
                    res.data.status  = res.data.status.toString()
                    this.form =  res.data
                    this.menuList = res.data['menu_list']
               }
               this.$nextTick(()=>{
                    this.toggleSelection()
                })
           })
       },
        handleAdd(){
            this.$api.get('Role/add',this.form,res=>{
                if(res.code == 200){
                    this.dialogAddVisible =  true
                    this.menuList =   res.data['menu_list']
					if(this.roleId == 1){
						this.$api.post('Company/getCompanyList',null,res=>{
						   if(res.code == 200){
							   this.coList =  res.data
						   }
						})
					}
                }else{
                    this.$message.error();
                }
            })
        },
        toggleSelection(){  //更新选中     
            var list  = this.menuList
            this.checkedMenu(list)
        },
        checkedMenu(arr){       //选中菜单
            arr.forEach(row=>{
                if(row.checked == 1){
                    // this.multipleSelection.push(row)
                    this.$refs['multipleTable'].toggleRowSelection(row);
                }
                 if(row['children']){
                     return this.checkedMenu(row['children'])
                 }
            })
        },
        handleAllSelection(val){
             this.$nextTick(() => {
                 var  tableData = this.menuList;
                 tableData.forEach(row=>{
                      this.getSelection(row,this.multipleSelection.length > 0?0:true) 
                 }) 
             })
        },
        handleChange(val){
            this.multipleSelection  = val
            this.form.selectedIds =  this.multipleSelection.map(function(item){return  item.id})
        },
        handleSelectionChange(val,row){
            let selected = val.length && val.indexOf(row) !== -1            //当前选中状态  
            this.$nextTick(() => {
                if(val){
                    var  tableData = this.menuList;
                    if (row.children) { //存在子节点
                        // console.log(val)
                        this.getSelection(row)
                    }else{  
                        // console.log(row.checked)
                        this.$refs['multipleTable'].toggleRowSelection(row,selected);
                    }
                }else{
                    this.$refs['multipleTable'].clearSelection()
                }
            })
        },

        getSelection(row,selected){

            if(row.children){
                // this.$refs['multipleTable'].toggleRowSelection(row,selected);
                row.children.forEach(item=>{
                    this.$refs['multipleTable'].toggleRowSelection(item,selected);
                    if(item.children){
                        this.getSelection(item)
                    }
                })
            }
        },
        confirm(){
           let url = 'Role/add'
            if(this.form.id){
                url =  'Role/edit'
           }
           this.$api.post(url,this.form,res=>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.dialogAddVisible  = false
					this.reload();
                }else{
                    this.$message.error(res.msg)
                }
            })
            // this.reload();
       },
        // handleSelectionChange(val){      //获取选中的菜单 处理成菜单id数组
        //     // this.multipleSelection =  arr
        //     // console.log(this.multipleSelection)
        //     this.multipleSelection  = val
        //     this.form.selectedIds =  this.multipleSelection.map(function(item){return  item.id})
        //     // console.log(this.form.selectedIds)
        // }
    },
}
</script>
<style scoped>

</style>