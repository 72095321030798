<template>
<div>
    <el-dialog title="添加人员" :visible.sync="dialogAddVisible" width="30%" 
			:close-on-click-modal="false"  
			 :append-to-body="true" 
			:modal-append-to-body="false">
        <el-form ref="form" :model="form" label-width="100px" :rules="rules" class="add-staff-box">
			<div class="add-title"><span class="title">选择人员</span></div>
			<el-form-item label="选择部门" prop="name">
				<el-cascader
					v-model="form.dept_id"  
					 :props="props" 
					:options="cascaderOptions" 
					  @change="handleChange"
					  ref="cascader"
					></el-cascader>
			</el-form-item>
			<!-- <div class="add-title"><span class="title">选择人员</span></div> -->
			<el-form-item label="选择成员" prop="name">
				<!-- 	<el-select v-model="form.admin_name" clearable placeholder="请选择" @change="(val)=>handleAdminSelect" :disabled="selectAdminStatus">
						<el-option value="" label="-">-</el-option> 
						<el-tree  :props="props"  node-key="id" :data="adminData"  highlight-current ref="tree" 
							@node-click="handleAdminNodeClick"> </el-tree>
					</el-select> -->
					<el-select v-model="form.admin_id" clearable placeholder="请选择" >
						<el-option v-for="item in adminData"  :label="item.name" :value="item.id">
						</el-option> 
					</el-select> 
			</el-form-item>
			<div  class="add-title"><span class="title">资产权限</span><span class="remind" >不选则为全部权限</span></div>
			<el-form-item label="资产分类">
				<el-select v-model="form.cate_name" multiple  clearable placeholder="请选择" @change="(val)=>handleSelect(val,'cate')">
					<el-option value="" label="-">-</el-option>
					<el-tree  show-checkbox node-key="id"
						:data="cateData"
						:props="treePorps"
					     highlight-current 
						 ref="cateTree" 
						@check-change="(val)=>handleNodeClick(val,'cate')"
						> </el-tree>
				</el-select>
			</el-form-item>
			<el-form-item label="资产位置" >
				<el-select v-model="form.location_name" multiple  clearable placeholder="请选择" @change="(val)=>handleSelect(val,'location')">
					<el-option value="" label="-">-</el-option>
					<el-tree show-checkbox   node-key="id" :data="locationData" :props="treePorps" highlight-current
					 ref="locationTree"
						@check-change="(val)=>handleNodeClick(val,'location')"> </el-tree>
				</el-select>
			</el-form-item>
			<div  class="add-title"><span class="title">耗材权限</span><span class="remind">不选则为全部权限</span></div>
			<el-form-item label="耗材分类">
				<el-select v-model="form.hc_cate_name" multiple  clearable placeholder="请选择" @change="(val)=>handleSelect(val,'hc_cate')">
					<el-option value="" label="-">-</el-option>
					<el-tree show-checkbox  node-key="id" :data="hcCateData" :props="treePorps" highlight-current
					 ref="hcCateTree"
						@check-change="(val)=>handleNodeClick(val,'hc_cate')"> </el-tree>
				</el-select>
			</el-form-item>
			<el-form-item label="耗材仓库" >
				<el-select v-model="form.hc_location_name" multiple  clearable placeholder="请选择" @change="(val)=>handleSelect(val,'hc_location')">
					<el-option value="" label="-">-</el-option>
					<el-tree show-checkbox  node-key="id" :data="hcLocationData" :props="treePorps" highlight-current
						ref="hcLocationTree"
						@check-change="(val)=>handleNodeClick(val,'hc_location')"> </el-tree>
				</el-select>
			</el-form-item>
         </el-tabs>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogAddVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm()">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
export default {
    inject:['reload'],
    data() {
        return {
            form:{
				role_id:'',
                id:'',
                admin_id:"",
				admin_name:"",
                cate_id:"",
				cate_name:[],
                hc_cate_id:"",
				hc_cate_name:"",
                location_id:"",
				location_name:"",
				hc_location_id:"",
				hc_location_name:"",
				dept_id:""
            },
			cascaderOptions:[],
			
            multipleSelection:[],
            dialogAddVisible:false,
			rules: {
				name: [{
					required: true,
					message: '请输入角色名称',
					trigger: 'blur'
				}],
			},
			adminData:[],
			cateData:[],
			locationData:[],
			hcLocationData:[],
			hcCateData:[],
			props:{
				value:'id',
				label:'name',
			},
			treePorps:{
				label:'name'
			},
			selectAdminStatus:false
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
        handleAdd(id){
			this.form.role_id = id
			console.log('roleid:',this.form.role_id)
            this.$api.get('Role/add',this.form,res=>{
                if(res.code == 200){
					// this.$api.get('admin/deptList',null,res=>{ //获取审批初始数据
					// 	this.adminData = res.data
					// 	 this.dialogAddVisible =  true
					// })
					this.$api.get('admin/getDeptAdmin',null,adminres=>{
					     if(adminres.code == 200){ 
							this.adminData =  adminres.data.list
							this.$api.get('AssetCategory/getCateAndLocation',null,res=>{	//资产分类
								if(res.code == 200){
									this.cateData = res.data.cate_list
									this.locationData =  res.data.location_list
									this.hcLocationData = res.data.hc_location_list
									this.hcCateData =  res.data.hc_cate_list
									this.$api.get('Department/deptList',null,res2=>{
										if(res2.code == 200){
											this.cascaderOptions =  res2.data.list
										}
									})
									this.dialogAddVisible =  true
								}
							})
					     }else{
					         this.$message.error(res.msg)
					     }
					 })
					
                   
                }else{
                    this.$message.error();
                }
            })
        },
		handleEdit(id){
			this.$api.get('Role/editStaff',{id:id},editres=>{
				if(editres.code == 200){
					this.form =  editres.data.info
					this.$api.get('admin/getDeptAdmin',null,res=>{
					     if(res.code == 200){
							this.adminData =  res.data.list
					     }else{
					         this.$message.error(res.msg)
					     }
					 })
					// this.$api.get('admin/deptList',null,res=>{ //获取审批初始数据
					// 	this.adminData = res.data
					var that =  this
						this.$api.get('AssetCategory/getCateAndLocation',null,res=>{	//资产分类
							this.cateData = res.data.cate_list
							this.locationData =  res.data.location_list
							this.hcLocationData = res.data.hc_location_list
							this.hcCateData =  res.data.hc_cate_list
							this.$api.get('Department/deptList',null,res2=>{
								if(res2.code == 200){
									this.cascaderOptions =  res2.data.list
									// that.$refs.cascader.setCheckedNodes([ 13, 14, 34, 69])  
								}  
								 
							}) 
							this.selectAdminStatus =  true
							this.dialogAddVisible =  true
						})
					// })
				}else{
					this.$message.error();
				}
			})
		},
		handleSelect(val,txt){
			var select_id =  txt+'_id'
			this.form.select_id =  val
		},
		
		
		handleNodeClick(data,txt){
			var select_id =  txt+'_id'
			var select_name  = txt+'_name'
			var nodes  = []
			console.log(txt)
			if(txt == 'cate'){
				this.form[select_id] = this.$refs.cateTree.getCheckedKeys()
				 nodes = this.$refs.cateTree.getCheckedNodes()
			}else if(txt  == 'location'){
				this.form[select_id] = this.$refs.locationTree.getCheckedKeys()
				 nodes = this.$refs.locationTree.getCheckedNodes()
				 console.log(this.$refs)
			}else if(txt == 'hc_cate'){
				this.form[select_id] = this.$refs.hcCateTree.getCheckedKeys()
				 nodes = this.$refs.hcCateTree.getCheckedNodes()
			}else if(txt == 'hc_location'){
				this.form[select_id] = this.$refs.hcLocationTree.getCheckedKeys()
				 nodes = this.$refs.hcLocationTree.getCheckedNodes()
			}

			this.form[select_name]  =  []
			nodes.forEach((item,index)=>{
				this.form[select_name].push(item.name)
			})
		
		// 	console.log(this.form[select_name])
		
		},

		handleAdminSelect(val){
			this.form.admin_id =  val
		},
		handleAdminNodeClick(val){
			this.form.admin_id =  val.id
			this.form.admin_name   = val.name
		},
        confirm(){
           this.$api.post('Role/addStaff',this.form,res=>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.dialogAddVisible  = false
					this.reload();
                }else{
                    this.$message.error(res.msg)
                }
            })
       },
	   handleChange(value){
		   console.log(value)
		  let dept_id = value[value.length-1] 
		 this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
		      if(res.code == 200){
					this.adminData =  res.data.list
		      }else{
		          this.$message.error(res.msg)
		      }
		  })
	   },

    },
}
</script>
<style lang="scss">
	.add-staff-box{
		.el-cascader,.el-select{
			width: 100% !important;
		}
	}
</style>
<style scoped lang="scss">
	.add-title{
		height: 40px;
		line-height: 40px;
		.title{
			font-size: 18px;
			font-weight: 600;
			margin-right: 10px;
			border-bottom: 2px solid #409EFF;
		}
		.remind{
			color:#909399;
			font-size: 12px;
		}
	}
</style>