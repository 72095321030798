<template>
<div  class="page-container">
	 <el-dialog title="人员列表" :visible.sync="dialogAdminVisible" width="60%" :close-on-click-modal="false" 
	 :append-to-body="true" 
	 :modal-append-to-body="false">
         <el-table
             :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"  
             border
             style="width: 100%">
             <el-table-column
                fixed
                prop="admin_name"
                label="成员">
             </el-table-column>
               <el-table-column
                fixed
                prop="asset_cate_name"
                label="授权审批的资产分类">
				<template slot-scope="scope">
					<span >{{scope.row.asset_cate_name?scope.row.asset_cate_name:'-'}}</span>
				</template>
             </el-table-column>
			 <el-table-column
			    empty-text='-'
			    prop="asset_location_name"
			    label="授权审批的资产位置">
				<template slot-scope="scope">
					<span >{{scope.row.asset_location_name?scope.row.asset_location_name:'-'}}</span>
				</template>
			 </el-table-column>
         	<el-table-column
         	   empty-text='-'
         	   prop="cse_cate_name"
         	   label="授权审批的耗材分类">
			   <template slot-scope="scope">
			   	<span >{{scope.row.cse_cate_name?scope.row.cse_cate_name:'-'}}</span>
			   </template>
         	</el-table-column>
			<el-table-column
			   empty-text='-'
			   prop="cse_location_name"
			   label="授权审批的耗材仓库">
			   <template slot-scope="scope">
			   	<span >{{scope.row.cse_location_name?scope.row.cse_location_name:'-'}}</span>
			   </template>
			</el-table-column>
            <el-table-column
             fixed="right"
             label="操作">
             <template slot-scope="scope">
				<el-button size="small" @click="handleAddStaff(scope.row.id)">编辑</el-button>
                <el-button  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
             </template>
             </el-table-column>
          </el-table>
         <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		 <AddStaffVue ref="addStaffDialog"></AddStaffVue>
	 </el-dialog>
    </div>
	
</template>

<script>
import AddStaffVue from './AddStaff.vue'
export default {
     inject:['reload'],
	 components:{
	 	AddStaffVue
	 },
    data() {
        return {
          tableData:[],
          dialogAdminVisible:false,
           currentPage:1,
           pagesize:10,
            total:0,
			roleId:0
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
         getData(){
           this.$api.post('Role/adminIndex',{
			   page:this.currentPage,
			   limit:this.pagesize,
			   role_id:this.roleId,
		   },res=>{
               if(res.code == 200){
                   this.tableData =  res.data.list
                   this.total =  res.data.count
				    this.dialogAdminVisible = true
               }
           })   
       },
	
	   handleAddStaff(id){  
		    this.$refs.addStaffDialog.handleEdit(id)
	   },
	   handleDel(row){
			this.$alert("确定删除该审批人员吗？", "删除提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.delete("role/delRoleAdmin", { "id": row.id }, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }else{
			                      this.$message.error(res.msg)
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
	   },

        getCurrPage(val){   //获取当前页
            this.currentPage =  val
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
        },
    },
}
</script>
<style scoped lang="scss">
 
</style>